import firebase from 'firebase/app';
import {useEffect} from "react";
import {useHistory} from "react-router-dom";

export function useMonitorLogouts(){
    const history = useHistory();
    useEffect(()=>{
        firebase.auth().onAuthStateChanged((user)=>{
             if(!user){
                 history.push("/");
             }
        })
    })
}

