import Grid from '@material-ui/core/Grid';
import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {
    Checkbox,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, makeStyles,
    RadioGroup,
    TextField,
    Typography
} from "@material-ui/core";
import TelInput from "../../components/masked-inputs/TelInput";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Radio } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import {useHistory, useLocation} from 'react-router-dom';
import {IsDateInFuture} from "../../utils/utils";
import Firebase from 'firebase';

const useStyles = makeStyles(theme=>(
    {
        cancelButton:{
            backgroundColor:'red',
            color:'white'
        },
    }));

const RoofingAgreement = () => {
    const db = Firebase.database();
    const isSmall = useMediaQuery('(min-width:600px)');
    const history = useHistory();
    const [openDialog, setOpenDialog] = useState(false);
    const [isNew, setIsNew] = useState(true);
    const [claimId, setClaimId] = useState('');
    const [isEditable, setIsEditable] = useState(true);
    const classes = useStyles();

    const handleSubmit = async (event:FormEvent)=>{
        event.preventDefault();
        if(claimData.phone_number == ''){
            setErrorState({...errorState,phoneNumberRequired: true});
            return;
        }
        if(errorState.invalidDOL || errorState.invalidDOB || errorState.invalidDeductible || errorState.phoneNumberRequired){
            return;
        }
        const amount = parseFloat(claimData.deductible);
        if(isNaN(amount)){
            setErrorState({...errorState, invalidDeductible: true})
            return;
        }

        if(isNew){
            const ref = db.ref('/roofing-agreements/');
            await ref.push({...claimData,
                               created_at:Firebase.database.ServerValue.TIMESTAMP,
                               updated_at:Firebase.database.ServerValue.TIMESTAMP});
            setOpenDialog(true);
        }else{
            const ref = db.ref(`/roofing-agreements/${claimId}`);
            await ref.set({...claimData,updated_at:Firebase.database.ServerValue.TIMESTAMP});
            setOpenDialog(true);
        }

    }

    const handleDialogClose = async()=>{
        setOpenDialog(false);
        history.replace('/main/claims');
    }

    const useQuery = ()=>{
        return new URLSearchParams(useLocation().search);
    }

    const queryParams = useQuery();


    const requestClaim = (claimId: string) => {
        if(claimId==='')
            return;
        const ref = db.ref(`/roofing-agreements/${claimId}`);
        ref.once("value").then(loadClaim)
    }

    const loadClaim = (data:Firebase.database.DataSnapshot)=> {
        updateClaimData(data.val());
    }

    useEffect(()=>{
        if(queryParams.has('id')){
            const id = queryParams.get('id')?.toString();
            const editMode = queryParams.get('edit');
            setIsNew(false);
            setClaimId(id??'');
            if(editMode && editMode === '1'){
                setIsEditable(true);
            }else{
                setIsEditable(false);
            }
            requestClaim(id??'');
        }
    },[])

    const typesOfDamage = [
        {name:'hail-damage', label:'Hail Damage'},
        {name:'wind-damage', label:'Wind Damage'},
        {name:'missing-shingles', label:'Missing Shingles'},
        {name:'discontinued-shingles', label:'Discontinued Shingles'},
    ];

    const claimPermissions = [
        {name:'cp-1', label:'I authorize Georgia Smart Services to file the claim on my behalf with my homeowner’s insurance.'},
        {name:'cp-2', label:'I hereby agree that I have selected Georgia Smart Services as my roofing contractor to perform a complete roof replacement, this agreement is contingent upon insurance price and approval by my home insurance provided.'},
        {name:'cp-3', label:'I permit entry to my property to inspect, measure, diagram, and photograph. And I give and permission to my contractor to meet with the adjuster, to share any information by mail phone or email related with the claim.'},
        {name:'cp-4', label:'Georgia Smart Services will not be responsible for any previous and/or existing damage to the property structure for example loose siding, holes on walls, broken windows, doors, ceiling, or anything that is not related to storm damages and/or is not included in the insurance work sheets/estimated.'},
    ];

    const colorOption = [
        {value:'black', label:'Black'},
        {value:'brown', label:'Brown'},
        {value:'white', label:'White'},
    ];

    const [errorState, setErrorState] = useState(
        {
            invalidDeductible:false,
            invalidDOB:false,
            invalidDOL:false,
            phoneNumberRequired:false,
        });

    const [claimData, updateClaimData] = useState({
        status:'open',
        client_name:'',
        property_address:'',
        phone_number:'',
        email_address:'',
        date_of_birth:'',
        insurance_company:'',
        deductible:'0.00',
        mortgage_name:'',
        location:'',
        type_of_damage:'',
        date_of_lost:'',
        claim_number:'',
        adjuster_information:'',
        hail_damage:false,
        wind_damage:false,
        missing_shingles:false,
        discontinued_shingles:false,
        cp_1:false,
        cp_2:false,
        cp_3:false,
        cp_4:false,
        drip_edge_color:'',
        architectural_shingles_color:'',
        additional_upgrades:'',
        other:'',
        created_at:'',
        updated_at:'',
        assigned:false,
        inspector:''
                                                  })
    const handleChange = async (event:ChangeEvent<HTMLInputElement>)=>{
        const {target} = event;
        if (!target) return;
        switch (target.id){
            case 'client-name':
                updateClaimData({...claimData, client_name: target.value});
                break;
            case 'property-address':
                updateClaimData({...claimData, property_address: target.value});
                break;
            case 'phone-number':
                if(target.value !== ''){
                    setErrorState({...errorState, phoneNumberRequired: false});
                    updateClaimData({...claimData, phone_number: target.value});
                }
                break;
            case 'email':
                updateClaimData({...claimData, email_address: target.value});
                break;
            case 'dob':
                if(target.value !== ''){
                    const dob = new Date();
                    dob.setTime(Date.parse(target.value));
                    if(IsDateInFuture(dob)){
                        setErrorState({...errorState, invalidDOB: true})
                        return;
                    }
                    setErrorState({...errorState, invalidDOB: false})
                }
                updateClaimData({...claimData, date_of_birth: target.value});
                break;
            case 'insurance-company':
                updateClaimData({...claimData, insurance_company: target.value});
                break;
            case 'deductible':
                setErrorState({...errorState, invalidDeductible: false})
                updateClaimData({...claimData, deductible: target.value});
                break;
            case 'mortgage':
                updateClaimData({...claimData, mortgage_name: target.value});
                break;
            case 'location':
                updateClaimData({...claimData, location: target.value});
                break;
            case 'date-of-lost':
                if(target.value !== ''){
                    const dol = new Date();
                    dol.setTime(Date.parse(target.value));
                    if(IsDateInFuture(dol)){
                        setErrorState({...errorState, invalidDOL: true})
                        return;
                    }
                    setErrorState({...errorState, invalidDOL: false})
                }
                updateClaimData({...claimData, date_of_lost: target.value});
                break;
            case 'claim-number':
                updateClaimData({...claimData, claim_number: target.value});
                break;
            case 'adjuster':
                updateClaimData({...claimData, adjuster_information: target.value});
                break;
            case 'drip-color':
                updateClaimData({...claimData, drip_edge_color: target.value});
                break;
            case 'shingles-color':
                updateClaimData({...claimData, architectural_shingles_color: target.value});
                break;
            case 'additional':
                updateClaimData({...claimData, additional_upgrades: target.value});
                break;
            case 'other':
                updateClaimData({...claimData, other: target.value});
                break;
        }
    }

    const handleCheckbox = async (id:string, checked:boolean)=>{
        switch (id){
            case 'cp-1':
                updateClaimData({...claimData, cp_1: checked});
                break;
            case 'cp-2':
                updateClaimData({...claimData, cp_2: checked});
                break;
            case 'cp-3':
                updateClaimData({...claimData, cp_3: checked});
                break;
            case 'cp-4':
                updateClaimData({...claimData, cp_4: checked});
                break;
            case 'hail-damage':
                updateClaimData({...claimData, hail_damage: checked});
                break;
            case 'wind-damage':
                updateClaimData({...claimData, wind_damage: checked});
                break;
            case 'missing-shingles':
                updateClaimData({...claimData, missing_shingles: checked});
                break;
            case 'discontinued-shingles':
                updateClaimData({...claimData, discontinued_shingles: checked});
                break;
        }
    }

    function hasDamage(name: string):boolean {
        switch (name){
            case 'hail-damage':
                return claimData.hail_damage;
            case 'wind-damage':
                return claimData.wind_damage;
            case 'missing-shingles':
                return claimData.missing_shingles;
            case 'discontinued-shingles':
                return claimData.discontinued_shingles;
            default:
                return false;
        }
    }

    function hasPermission(name: string) :boolean {
        switch (name){
            case 'cp-1':
                return claimData.cp_1;
            case 'cp-2':
                return claimData.cp_2;
            case 'cp-3':
                return claimData.cp_3;
            case 'cp-4':
                return claimData.cp_4;
            default:
                return false;
        }
    }

    function handleClose() {
        history.replace('/main/claims');
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                    <Typography variant={'h4'}>
                        Roofing Agreement
                    </Typography>
                </Grid>
                <Grid item={true} xs={12}>
                    <Typography variant={'subtitle1'} color={claimData.status==='open'?'primary':'secondary'}>
                        Status: ({claimData.status})
                    </Typography>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        id={'client-name'}
                        value={claimData.client_name}
                        onChange={handleChange}
                        variant={'filled'} label={'Client Name:'} fullWidth={true}
                        required={true}
                        disabled={!isEditable}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        id={'property-address'}
                        value={claimData.property_address}
                        onChange={handleChange}
                        variant={'filled'} label={'Property Address:'} fullWidth={true}
                        required={true}
                        disabled={!isEditable}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={4}>
                    <TextField
                        id={'phone-number'}
                        value={claimData.phone_number}
                        onChange={handleChange}
                        variant={'filled'}
                        label={'Phone Number:'}
                        type={'tel'} fullWidth={true}
                        InputProps={{inputComponent: TelInput}}
                        required={true}
                        disabled={!isEditable}
                        error={errorState.phoneNumberRequired}
                        helperText={errorState.phoneNumberRequired?'Phone Number is Required':''}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={4}>
                    <TextField id={'email'}
                               value={claimData.email_address}
                               variant={'filled'}
                               label={'E-mail Address:'}
                               disabled={!isEditable}
                               type={'email'} fullWidth={true}
                               onChange={handleChange}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={4}>
                    <TextField id={'dob'}
                               value={claimData.date_of_birth}
                               variant={'filled'}
                               label={'Date of Birth:'}
                               type={'date'} fullWidth={true}
                               onChange={handleChange}
                               InputLabelProps={{
                                   shrink:true
                               }}
                               error={errorState.invalidDOB}
                               helperText={errorState.invalidDOB?'invalid date in future':''}
                               disabled={!isEditable}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        id={'insurance-company'}
                        value={claimData.insurance_company}
                        variant={'filled'}
                        label={'Insurance Company:'}
                        fullWidth={true}
                        onChange={handleChange}
                        required={true}
                        disabled={!isEditable}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={12} md={3} lg={3} xl={2}>
                    <TextField
                        id={'deductible'}
                        value={claimData.deductible}
                        variant={'filled'}
                        label={'Deductible:'}
                        fullWidth={true}
                        onChange={handleChange}
                        error={errorState.invalidDeductible}
                        helperText={errorState.invalidDeductible?'invalid value':''}
                        required={true}
                        disabled={!isEditable}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={8}>
                    <TextField
                        id={'mortgage'}
                        value={claimData.mortgage_name}
                        variant={'filled'}
                        label={'Mortgage Name:'}
                        fullWidth={true}
                        onChange={handleChange}
                        required={true}
                        disabled={!isEditable}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={12} md={3} lg={3} xl={2}>
                    <RadioGroup
                                onChange={handleChange}
                                row={true}
                                value={claimData.location}
                    >
                        <FormControlLabel
                            value={'local'}
                            control={<Radio id={'location'} color={'primary'} disabled={!isEditable}/>}
                            label={'Local'}
                            labelPlacement={'top'}
                        />
                        <FormControlLabel
                            value={'out-state'}
                            control={<Radio id={'location'} color={'primary'} disabled={!isEditable}/>}
                            label={'Out of State'}
                            labelPlacement={'top'}
                        />
                    </RadioGroup>
                </Grid>
                <Grid item={true} xs={12}>
                    <Typography variant={'h4'}>
                        CLAIM DAMAGE INFORMATION
                    </Typography>
                </Grid>
                <Grid container={true} item={true} xs={12} direction={!isSmall?'column':'row'}>
                    <Typography variant={'h5'}>
                        TYPES OF DAMAGE:&nbsp;
                    </Typography>
                    {typesOfDamage.map(damage=>{
                        return(
                            <FormControlLabel
                                id={damage.name}
                                key={damage.name}
                                value={damage.name}
                                control={
                                    <Checkbox name={damage.name}
                                              color={'primary'}
                                              checked={hasDamage(damage.name)}
                                              disabled={!isEditable}
                                    />
                                }
                                label={damage.label}
                                labelPlacement={'end'}
                                onChange={async (event,checked)=>{await handleCheckbox(damage.name,checked)}}
                            />
                        );
                    })}
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                    <TextField id={'date-of-lost'}
                               value={claimData.date_of_lost}
                               variant={'filled'}
                               label={'Date of Lost:'}
                               type={'date'} fullWidth={true}
                               disabled={!isEditable}
                               onChange={handleChange}
                               InputLabelProps={{
                                   shrink:true
                               }}
                               error={errorState.invalidDOL}
                               helperText={errorState.invalidDOL?'invalid date in future':''}
                               required={true}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                    <TextField id={'claim-number'}
                               value={claimData.claim_number}
                               variant={'filled'}
                               label={'Claim Number:'}
                               fullWidth={true}
                               disabled={!isEditable}
                               onChange={handleChange}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField id={'adjuster'}
                               value={claimData.adjuster_information}
                               variant={'filled'}
                               label={'Adjuster Information:'}
                               fullWidth={true}
                               disabled={!isEditable}
                               onChange={handleChange}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <Typography variant={'h5'}>
                        CLAIM PERMISSION
                    </Typography>
                </Grid>
                <Grid item={true} xs={12}>
                    {claimPermissions.map(permission=>{
                        return (
                            <FormControlLabel
                                id={permission.name}
                                key={permission.name}
                                control={
                                    <Checkbox name={permission.name} color={'primary'}
                                              checked={hasPermission(permission.name)}
                                              disabled={!isEditable}
                                    />}
                                onChange={async (event,checked)=>{await handleCheckbox(permission.name,checked)}}
                                label={permission.label} labelPlacement={'end'}/>
                        )
                    })}

                </Grid>
                <Grid item={true} xs={12}>
                    <Typography variant={'h4'}>
                        Scope of Work
                    </Typography>
                </Grid>
                <Grid item={true} xs={12}>
                    <Typography variant={'h5'}>
                        Drip edge color option:
                    </Typography>
                    <RadioGroup row={isSmall} onChange={handleChange} value={claimData.drip_edge_color}>
                        {colorOption.map(color=>{
                            return(
                                <FormControlLabel
                                    key={color.value}
                                    value={color.value}
                                    control={<Radio id={'drip-color'} color={'primary'} disabled={!isEditable}/>}
                                    label={color.label}
                                    labelPlacement={'end'}
                                />
                            );
                        })}
                    </RadioGroup>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField id={'shingles-color'} variant={'filled'}
                               value={claimData.architectural_shingles_color}
                               label={'Architectural shingles color:'}
                               fullWidth={true}
                               disabled={!isEditable}
                               onChange={handleChange}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField id={'additional'} variant={'filled'}
                               value={claimData.additional_upgrades}
                               multiline={true}
                               label={'Additional Upgrades or non-insurance related item:'}
                               fullWidth={true}
                               rows={4}
                               disabled={!isEditable}
                               onChange={handleChange}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField id={'other'} variant={'filled'}
                               value={claimData.other}
                               label={'Other:'}
                               fullWidth={true}
                               disabled={!isEditable}
                               onChange={handleChange}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <Typography variant={'subtitle1'}>
                        Inspector: {claimData.assigned?claimData.inspector:'(Not Assigned)'}
                    </Typography>
                </Grid>
                <Grid item={true} xs={12}>
                    {isEditable &&
                        (<div>
                            <Button variant={'contained'} color={'primary'} type={'submit'}>Save Changes</Button>
                            <Button variant={'contained'} className={classes.cancelButton} onClick={handleClose}>Cancel</Button>
                        </div>)
                    }
                    {!isEditable &&
                        <Button variant={'contained'} color={'primary'} onClick={handleClose}>Close</Button>
                    }

                </Grid>
            </Grid>
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
            >
                <DialogTitle>Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Roofing agreement saved successfully.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant={'contained'} color={'primary'}>Ok</Button>
                </DialogActions>
            </Dialog>
        </form>
    );
};

export default RoofingAgreement;
