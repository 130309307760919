export const GetTodayWithoutTime = ():Date=>{
    const today = new Date();
    today.setHours(0,0,0,0);
    return today;
}

export const IsDateInFuture = (date:Date):boolean =>{
    const today = GetTodayWithoutTime();
    if(date > today){
        return true;
    }
    return false;
}
