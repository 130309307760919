import React, {ChangeEvent, FormEvent, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {FaKey} from 'react-icons/fa'
import {MdAccountCircle} from "react-icons/all";
import logo from '../assets/logo.png';
import firebase from 'firebase/app';
import {
    FormControl,
    Card,
    CardContent,
    CardMedia,
    Container,
    CardActions,
    makeStyles,
    TextField,
    InputAdornment, Typography, CardActionArea
} from "@material-ui/core";
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
    root:{
        marginTop:'2rem'
    },
    media:{
        height:198,
        backgroundSize:'contain'
    },
    inputs:{
        display: 'flex',
        flexDirection: 'column'
    },
    card:{
        padding:'1rem'
    },
                             });

const Login = ()=>{
    const history = useHistory();
    const [state, setState] = useState({
                                            user:'',
                                            password:'',
                                            error:false,
                                            errorMsgs:[] as string[]
                                       })

    const onChange = async (event:ChangeEvent<HTMLInputElement>)=>{
        switch (event.target.id){
            case 'user':
                setState({...state, user: event.target.value, errorMsgs: [] as string[]});
                break;
            case 'password':
                setState({...state, password: event.target.value, errorMsgs: [] as string[]});
                break;
        }
    }

    const doLogin = async (event:FormEvent)=>{
        const errMsgs = state.errorMsgs;
        try{
            event.preventDefault();
            firebase.auth().onAuthStateChanged((user)=>{
                if(user){
                    history.push('/main');
                }
            })
            const result = await firebase.auth().signInWithEmailAndPassword(state.user, state.password);
        }catch (err){
            switch (err.code){
                case "auth/invalid-email":
                case "auth/user-disabled":
                case "auth/user-not-found":
                case "auth/wrong-password":
                    errMsgs.push('Invalid Credentials');
                    setState({...state,error:true, errorMsgs: errMsgs});
                    break;
                default:
                    errMsgs.push('An error occurred durin login');
                    setState({...state,error:true, errorMsgs: errMsgs});
            }
        }
    }

    const classes = useStyles();

    return (
        <Container maxWidth={'sm'} className={classes.root}>
            <form onSubmit={doLogin}>
                <Card className={classes.card}>
                    <CardMedia
                        className={classes.media}
                        image={logo}
                    />
                    <CardContent className={classes.inputs}>
                        <Typography gutterBottom={true} variant={'h5'} component={'h2'} align={'center'}>
                            Claim Tracking System
                        </Typography>
                        <FormControl>
                            <TextField
                                id={'user'}
                                required={true}
                                type={'email'}
                                value={state.user}
                                onChange={onChange}
                                InputProps={{
                                    startAdornment:(
                                        <InputAdornment position={'start'}>
                                            <MdAccountCircle/>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField id={'password'} required={true} type={'password'}
                                   onChange={onChange}
                                   value={state.password}
                                   InputProps={{
                                       startAdornment:(
                                           <InputAdornment position={'start'}>
                                               <FaKey/>
                                           </InputAdornment>
                                       )
                                   }}
                            />
                        </FormControl>
                    </CardContent>
                    <CardActions>
                        <Button color={'primary'} type={'submit'} variant={'contained'}>
                            Login
                        </Button>
                    </CardActions>
                    {state.error &&
                        <CardContent>
                            {state.errorMsgs.map((errMsg,index)=>{
                                return <Typography
                                    variant={'subtitle1'}
                                    key={`login-err-msg-${index}`}
                                    color={'error'}>{errMsg}</Typography>
                            })}
                        </CardContent>
                    }
                </Card>
            </form>
        </Container>
    );
};

export default Login;
