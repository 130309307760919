import React from 'react';
import {IfFirebaseAuthed} from "@react-firebase/auth";
import {useMonitorLogouts} from "../hooks/useMonitorLogout";
import firebase from 'firebase/app';
import {MdAccountCircle, MdExitToApp, MdAssignment} from "react-icons/md";
import SideBar from "../components/sidebar/SideBar";
import {Route, Switch, useHistory, useRouteMatch} from 'react-router-dom';
import Claims from './Claims';
import Landing from "./Landing";
import RoofingAgreement from "./forms/RoofingAgreement";
import {AppBar, Container, Toolbar} from "@material-ui/core";

const Main = () => {
    useMonitorLogouts();
    const history = useHistory();
    const {path} = useRouteMatch();
    const routes = [
        {path:`/claims/new-claim`, component:<RoofingAgreement/>},
        {path:`/claims/edit-claim`, component:<RoofingAgreement/>},
        {path:`/claims/view-claim`, component:<RoofingAgreement/>},
        {path:`/claims`, component:<Claims/>},
        {path:``,component: <Landing/>}
    ];

    const doLogout = async ()=>{
        await firebase.auth().signOut();
    }

    const sideBarOptions = [
        {text:'Profile',icon:<MdAccountCircle className={'side-bar-icon'}/>,value:'profile'},
        {text:'Claims',icon:<MdAssignment className={'side-bar-icon'}/>,value:'claims'},
        {text:'',icon:null,value:'divider'},
        {text:'Sign Out',icon:<MdExitToApp className={'side-bar-icon'}/>,value:'sign-out'}
    ];

    const handleSideBarOption = async (value:string)=>{

        switch (value){
            case 'sign-out':
                await doLogout();
                break;
            case 'claims':
                history.push(`${path}/claims`);
                break;
        }
    }

    return (
        <div>
            <SideBar sideBarOptions={sideBarOptions} handleClick={handleSideBarOption}/>
            <IfFirebaseAuthed>
                {()=>{
                    return(
                        <Container maxWidth={false}>
                        <Switch>
                            {routes.map((route,index)=>{
                                return <Route key={`route-${path}-${index}`} path={`${path}${route.path}`}>
                                    {route.component}
                                </Route>
                            })}
                        </Switch>
                        </Container>
                    );
                }}
            </IfFirebaseAuthed>
        </div>
    );
};

export default Main;
