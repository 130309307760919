import React from 'react';
import {InputBaseComponentProps} from "@material-ui/core";
import MaskedInput from 'react-text-mask';
const TelInput = (props: InputBaseComponentProps) => {
    const {inputRef, ...other} = props;

    return <MaskedInput
        {...other}
        ref={(ref:any)=>{
            inputRef(ref? ref.inputElement : null);
        }}
        mask={['(',/\d/,/\d/,/\d/,')',' ',/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/]}
        placeholderChar={'\u2000'}
        showMask={true}
    />
};

export default TelInput;
