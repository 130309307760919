import React, {useState} from 'react';
import {
    AppBar,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography
} from "@material-ui/core";
import {MdChevronLeft, MdMenu} from "react-icons/md";

type sideBarOption={
    text:string;
    icon:any;
    value:string;
}


type sideBarProps = {
    sideBarOptions:sideBarOption[];
    handleClick: (value:string)=>void;
}

const SideBar = (props:sideBarProps) => {
    const [sideBarOpen, setSideBarOpen] = useState(false);
    const {sideBarOptions, handleClick} = props;
    const openSideBar = ()=>{
        setSideBarOpen(true);
    }

    const closeSideBar = ()=>{
        setSideBarOpen(false);
    }
    return (
        <div id={'SideBarContainer'}>
            <AppBar position={'sticky'}>
                <Toolbar>
                    <IconButton onClick={openSideBar} edge={'start'}>
                        <MdMenu/>
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Claim Tracking System
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer variant={'persistent'}
                    anchor={'left'}
                    open={sideBarOpen}
            >
                <div>
                    <IconButton onClick={closeSideBar}>
                        <MdChevronLeft/>
                    </IconButton>
                </div>
                <Divider/>
                <List>
                    {sideBarOptions.map((option, index)=>{
                        if(option.value !== 'divider'){
                            return  <ListItem key={`${option.value}-${index}`} button={true} onClick={()=>{handleClick(option.value); closeSideBar();}}>
                                <ListItemIcon key={`${option.value}-icon-${index}`}>{option.icon}</ListItemIcon>
                                <ListItemText key={`${option.value}-text-${index}`} primary={option.text}/>
                            </ListItem>
                        }else{
                            return <Divider key={`${option.value}-${index}`}/>
                        }

                    })}
                </List>
            </Drawer>
        </div>
    );
};

export default SideBar;
