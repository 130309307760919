import React, {useEffect} from 'react';
import {MdAddCircle, MdSearch, MdEdit, MdVisibility} from 'react-icons/md';
import {useHistory, useRouteMatch} from "react-router-dom";
import {Container, IconButton, makeStyles, TextField, Typography} from "@material-ui/core";
import {innerPageRoot} from "./styles";
import { Grid } from '@material-ui/core';
import {DataGrid, GridCellParams, GridColDef, GridValueGetterParams} from '@material-ui/data-grid'
import { useState } from 'react';
import Firebase from "firebase";



const useStyles = makeStyles(theme=>(
    {
        innerPageRoot,
        searchIcon:{
            width:'2rem',
            height:'2rem'
        },
    }));



const Claims = () => {
    const db = Firebase.database();
    const history = useHistory();
    const {path} = useRouteMatch();
    const [claims, setClaims] = useState([] as any[]);
    const [pagination, setPagination] = useState({pageSize:3,pageNumber:0})

    const columns: GridColDef[] = [
        {field:'clientName', headerName:'Client Name', width:200},
        {field:'claimNumber', headerName:'Claim Number', width:200},
        {field:'inspector', headerName:'Inspector', width:200},
        {
            field:'id', headerName:' ', width:100,
            renderCell: (params: GridCellParams) => (
                <div>
                    <IconButton onClick={()=>{
                        if(params && params.value)
                            viewClaim(params.value.toString())
                    }}>
                        <MdVisibility/>
                    </IconButton>
                    <IconButton onClick={()=>{
                        if(params && params.value)
                            editClaim(params.value.toString())
                    } }>
                        <MdEdit/>
                    </IconButton>
                </div>
            )
        }
    ];

    const handleNewClaim = ()=>{
        history.push(`${path}/new-claim`);
    }

    const requestClaims = ()=>{
        const ref = db.ref('/roofing-agreements/');
        ref.orderByChild("status")
            .equalTo("open")
            .on('value',loadClaim);

    }

    const loadClaim = (data:Firebase.database.DataSnapshot)=>{
        const claimEntries = [] as any[];
        data.forEach(entry=>{
            const id = entry.key;
            const values = entry.val();
            claimEntries.push({id:id,clientName:values.client_name, claimNumber:values.claim_number, inspector:values.inspector});
        })
        setClaims(claimEntries);
    }

    const editClaim = (claimId: string) =>{
        history.push(`${path}/edit-claim?id=${claimId}&edit=1`);
    }
    const viewClaim = (claimId: string) =>{
        history.push(`${path}/view-claim?id=${claimId}`);
    }

    useEffect(()=>{
        requestClaims();
    },[])

    const classes = useStyles();
    return (
        <Container className={classes.innerPageRoot} >
            <Grid container={true} spacing={1}>
                <Grid container={true} item={true} xs={12}>
                    <Grid item xs={12}>
                        <Typography variant={'h4'}>
                            Claims
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item={true} xs={10}>
                    <TextField variant={'filled'} label={'Search parameter'} fullWidth={true}/>
                </Grid>
                <Grid item={true} xs={1}>
                    <IconButton>
                        <MdSearch className={classes.searchIcon}/>
                    </IconButton>
                </Grid>
                <Grid item={true} xs={1}>
                    <IconButton aria-label={'addnew'} color={'primary'} onClick={handleNewClaim}>
                        <MdAddCircle className={classes.searchIcon}/>
                    </IconButton>
                </Grid>
                <Grid container={true} item={true} xs={12}>
                    <DataGrid columns={columns}
                              rows={claims}
                              autoHeight={true}
                              columnBuffer={2}
                              rowHeight={30}/>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Claims;
