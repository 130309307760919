import React from 'react';
import logo from '../assets/logo.png';
import {Container, makeStyles} from "@material-ui/core";
import {innerPageRoot} from './styles';

const useStyles = makeStyles({
                              innerPageRoot,
                          });

const Landing = () => {
    const classes = useStyles();
    return (
        <Container maxWidth={'xs'} className={classes.innerPageRoot}>
            <img src={logo}/>
        </Container>
    );
};

export default Landing;
