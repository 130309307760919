import React from 'react';
import './App.css';
import Login from "./pages/Login";
import { Route, Switch} from 'react-router-dom';
import firebase from 'firebase/app';
import "firebase/auth";
import {FirebaseAuthProvider} from "@react-firebase/auth";
import {firebaseConfig} from './config/firebase.config';
import Main from "./pages/Main";
import {myTheme} from './theme';
import {createMuiTheme, ThemeOptions, ThemeProvider} from '@material-ui/core/styles';

const theme = createMuiTheme(myTheme as ThemeOptions);

function App() {
  const routes = [
        {path:'/main', component:<Main/>},
        {path:'/', component:<Login/>},

  ]

  return (
      <FirebaseAuthProvider {...firebaseConfig} firebase={firebase}>
          <ThemeProvider theme={theme}>
              <Switch>
                  {routes.map((route,index)=>{
                      return <Route key={`route-${index}`} path={route.path}>
                          {route.component}
                      </Route>
                  })}
              </Switch>
          </ThemeProvider>
      </FirebaseAuthProvider>
  );
}

export default App;
